<script setup>
import { ref } from 'vue';
import { useCoin2CoreStore } from '@/stores/coin2core';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';

const store = useCoin2CoreStore();
const router = useRouter();
const toast = useToast();

//const showSecondaryAuth = ref(false);
const loading = ref(false);
const passcode = ref('');
const selectedContact = ref(null);
const submitted = ref(false);

const rules = {
  selectedContact: { requiredIfSend: requiredIf(store.sendCodeFlow) },
  passcode: { requiredIfVerify: requiredIf(store.DialogverifyCodeFlow) }
}

const v$ = useVuelidate(rules, { selectedContact, passcode });

//const requestId = ref('');
async function sendPasscode() {            
    submitted.value = true;
    passcode.value = '';
    const isFormValid = await v$.value.$validate();  
    if(!isFormValid) {
        v$.value.$touch();
        return;
    }
    loading.value = true;
    let resp = await store.sendSecondaryAuthRequest(selectedContact.value.coreId);
    loading.value = false;
    if(resp) {
        submitted.value = false;
        store.sendCodeFlow = false;
        store.verifyCodeFlow = true;
    } else {
        toast.add({ severity: 'error', summary: store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'send_passcode_popup_header'), detail: store.error, life: 5000});
    }
}

async function verifyPasscode() {
    submitted.value = true;
    const isFormValid = await v$.value.$validate();  
    if(!isFormValid) {
        v$.value.$touch();
        return;
    }
    loading.value = true;
    let resp = await store.verifySecondaryAuthRequest(selectedContact.value.coreId, passcode.value);
    loading.value = false;
    if(resp) {
        submitted.value = false;
        store.showSecondaryAuth = false;
        selectedContact.value = null;
        store.sendCodeFlow = true;
        store.verifyCodeFlow = false;
        passcode.value = '';        
        // Right now login is treated special, we may need to change this
        //console.info('verify done - secondary action is:', store.secondaryAuthAction);
        if(store.secondaryAuthAction === 'LOG') {
            if(store.passwordChangeRequired) {
            router.push( { name: 'passwordChange' })
            } else {
            router.push( { name: 'wallet' });    
            }
        } else {            
            store.finishSecondaryAuthentication();
        }
    } else {        
        toast.add({ severity: 'error', summary: store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'verify_passcode_popup_header'), detail: store.error, life: 5000});
    }
}

async function cancelVerification() {
    store.showSecondaryAuth = false;
    selectedContact.value = null;
    store.secondaryAuthAction = '';
    passcode.value = '';
    store.sendCodeFlow = true;
    store.verifyCodeFlow = false;
    submitted.value = false;
}
</script>

<template>
    <div>  
        <Dialog v-model:visible="store.showSecondaryAuth" :header="store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'secondary_auth_title')" modal modal:breakpoints="{'2000px': '50vw', '700px': '95vw'}">            
            <div class="flex flex-column justify-content-center" v-if="store.sendCodeFlow">        
                <p v-html="store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'send_passcode_message')"></p>
                <br />
                <div class="p-fluid grid formgrid">
                    <div class="field col-12 md:col-12 sm:col-12">
                        <label :class="{ 'p-error': v$.selectedContact.$invalid && submitted }">{{ store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'select_contact_label') }} <small>*</small></label>                        
                        <SelectButton v-model="selectedContact" :options="store.personContacts" optionLabel="name" style="display: block;" :class="{ 'p-invalid': v$.selectedContact.$invalid && submitted }">                            
                        </SelectButton>
                    </div>                                                                                 
                </div>
            </div>
            
            <div class="flex flex-column justify-content-center" v-if="store.verifyCodeFlow">        
                <p v-html="store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'verify_passcode_message')"></p>
                <br />
                <div class="p-fluid grid formgrid">
                    <div class="field col-12 md:col-12 sm:col-12">
                        <label :class="{ 'p-error': v$.passcode.$invalid && submitted }">{{ store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'passcode_label') }} <small>*</small></label>                        
                        <InputText v-model="passcode" :class="{ 'p-invalid': v$.passcode.$invalid && submitted }" @keyup.enter="verifyPasscode" />
                    </div>                     
                </div>
            </div>     

            <template #footer>
                <div class="flex flex-column justify-content-center align-items-center">
                    <Button @click="sendPasscode()" :label="store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'send_passcode_btn')" v-if="store.sendCodeFlow && !loading" class="button-style" />       
                    <Button @click="verifyPasscode()" :label="store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'verify_passcode_btn')" v-if="store.verifyCodeFlow && !loading" class="button-style" />                            
                    <Button @click="cancelVerification()" :label="store.getPortalContentByPageAndContentName('SecondaryAuthentication', 'cancel_btn')" v-if="!loading" class="p-button-outlined button-style" />
                    <ProgressSpinner v-if="loading" class="button-progress-spinner"/>
                </div>    
            </template>
        </Dialog>      
    </div>  
</template>