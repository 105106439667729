import { createApp } from 'vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';

import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Skeleton from 'primevue/skeleton';
import Chart from 'primevue/chart';
import Password from 'primevue/password';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import SelectButton from 'primevue/selectbutton';
import InputNumber from 'primevue/inputnumber';
import ProgressSpinner from 'primevue/progressspinner';

import BrandLogo from '@/components/BrandLogo.vue';

import App from './App.vue';
import router from './router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icon sets */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './assets/sass/master.scss';
import './assets/sass/_theme.scss';
import './assets/sass/daland.scss'

import QRCodeVue3 from 'qrcode-vue3';

library.add(fas);
library.add(fab);

const app = createApp(App);

const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);

app.component('InputText', InputText);
app.component('Card', Card);
app.component('Button', Button);
app.component('Skeleton', Skeleton);
app.component('Chart', Chart);
app.component('Password', Password);
app.component('Dialog', Dialog);
app.component('InputMask', InputMask);
app.component('Calendar', Calendar);
app.component('SelectButton', SelectButton);
app.component('InputNumber', InputNumber);
app.component('Divider', Divider);
app.component('Toast', Toast);
app.component('QRCodeVue3', QRCodeVue3);
app.component('ProgressSpinner', ProgressSpinner);
app.component('BrandLogo', BrandLogo);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount('#app');
