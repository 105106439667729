import { defineStore, storeToRefs } from 'pinia';
import { ref, computed, nextTick } from 'vue';
import { CodeEngineCoin2CoreClient, useCodeEngineStore } from '@daland_cuso/code-engine-js-client';
import { useRouter } from 'vue-router';

const clientId = 'DL';
//const apiUrl = 'https://dcce-stcloud-code-engine-api-test.azurewebsites.net/';
//const apiUrl = 'https://code-engine-api-dev.azurewebsites.net/';
//const apiUrl = 'https://api-code-engine-test.dalandcuso.com/';
// const apiUrl = 'https://localhost:7136/';
//const apiKey = 'STC2CKEY';
const apiUrl = import.meta.env.VITE_CODE_ENGINE_API_URL;
const apiKey = import.meta.env.VITE_CODE_ENGINE_API_KEY;

export const useCoin2CoreStore = defineStore('coin2Core', () => {  
  const router = useRouter();
  const codeEngineStore = useCodeEngineStore();

  const username = ref('');
  const sessionId = ref('');
  const error = ref('');
  const personId = ref('');
  const loginId = ref('');
  const configuration = ref({});
  const creditUnionName = ref('');
  const creditUnionEmail = ref('');
  const creditUnionPhone = ref('');
  const configuredCurrencies = ref([]);
  const cryptoWallets = ref([]);
  const branding = ref({});  
  const features = ref([]);
  const emails = ref([]);
  const phoneNumbers = ref([]);
  const showSecondaryAuth = ref(false);
  const secondaryAuthAction = ref('');
  const secondaryAuthRequestId = ref('');
  const secondaryAuthSuccess = ref(false);
  const codeEngineCoin2CoreClient = ref(new CodeEngineCoin2CoreClient());
  const sendCodeFlow = ref(true);
  const verifyCodeFlow = ref(false);
  const portalTypeId = ref(5);
  const passwordChangeRequired = ref(false);

  function getWalletByType(currencyId) {          
    let wallet = cryptoWallets.value.find((type) => type.walletType === currencyId);
    if(!wallet) {
      return {};
    } else {
      return wallet;
    }    
  };     

  const portalTimeout = computed(() => {            
    if(configuration.value && configuration.value.portalTimeout)  {
      return configuration.value.portalTimeout;
    } else {
      return 10; // Default to 10 min
    }
  });
      
  const portalBranding = computed(() => {
    if(codeEngineStore.branding) {
      return codeEngineStore.branding;
    } else {
      return {};
    }
  })
  const personContacts = computed(() => {
    let personContacts = [];
    if(phoneNumbers.value) {
      for(let i = 0; i < phoneNumbers.value.length; i++) {        
        let contact = phoneNumbers.value[i];
        let newContact = {};          
        newContact.name = contact.typeDescription + ' - '  + contact.maskedNumber;          
        newContact.coreId = contact.coreId;

        if(contact.maskedNumber && configuration.value.smsEnabled) {
          personContacts.push(newContact);
        }
      }
    }
  

    if(emails.value) {
      for(let i = 0; i < emails.value.length; i++) {        
        let contact = emails.value[i];
        let newContact = {};          
        newContact.name = contact.typeDescription + ' - '  + contact.maskedEmailAddress;          
        newContact.coreId = contact.coreId;

        if(contact.maskedEmailAddress && configuration.value.emailEnabled) {
          personContacts.push(newContact);
        }
      }
    }
    return personContacts;
  })

  const isFeatureAvailable = ((featureName) => {
    if(features.value) {
      let feature = features.value.find(x => x.name === featureName);
      if(feature) {
        return feature.isEnabled;
      } else {
        return true; // If it's not found we assume it's enabled
      }
    }
  })

  const featureRequiresSecondaryAuth = ((featureName) => {      
    if(features.value) {
      let feature = features.value.find(x => x.name === featureName);
      if(feature) {
        return feature.secondaryAuthenticationRequired;
      } else {
        return false; // If it's not found, we assume it's not set, therefore not required
      }
    }
  })
  
  async function startSession() {
    codeEngineCoin2CoreClient.value.init(clientId, apiUrl, apiKey);
    let resp = await codeEngineCoin2CoreClient.value.startSession();
    codeEngineStore.init(clientId, apiUrl, apiKey);
    if(resp.sessionId) {
      sessionId.value = resp.sessionId;
    } else {
      error.value = resp.error;
    }
  }

  async function getConfiguration() {
    let resp = await codeEngineCoin2CoreClient.value.getConfiguration();
    let loadBranding = await codeEngineStore.loadPortalBranding(portalTypeId.value);
    let portalContentResp = await codeEngineStore.loadPortalContent(portalTypeId.value);  
    let errorMappingResp = await codeEngineStore.loadErrorMapping(portalTypeId.value);  
    if(resp.success) {
      configuration.value = resp;
      creditUnionName.value = resp.creditUnionName;
      creditUnionPhone.value = resp.creditUnionPhone;
      creditUnionEmail.value = resp.creditUnionEmail;
      features.value = resp.features;       
      return resp;
    } else {
      error.value = "Unable to connect to services";
    }
  }

  async function validateIpAddress() {
    let resp = await codeEngineCoin2CoreClient.value.validateIpAddress();

    if(resp.success) { 
      return resp.isValidIp; 
    } else {
      return false;
    }
  }

  async function loginUser(un, pwd) {
    let resp = await codeEngineCoin2CoreClient.value.login(un, pwd);    
    if(resp.success) {      
      username.value = un;
      personId.value = resp.personId;
      loginId.value = resp.loginId;
      if(resp.personDetails) {
        emails.value = resp.personDetails.emails;
        phoneNumbers.value = resp.personDetails.phoneNumbers;
      }      
          
      if(resp.passwordChangeRequired) {
        passwordChangeRequired.value = true;
      }
      return true;
    } else {      
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      
      return false;
    }    
  }

  async function changeLoginPassword(oldPwd, newPwd) {
    let resp = await codeEngineCoin2CoreClient.value.changeLoginPassword(username.value, oldPwd, newPwd, portalTypeId.value);
    if(resp.success) {
      return true;
    } else {
      // This can have core specific messages around bad password stuff we want to preserve
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode, resp.errorMessage);
      return false;
    }
  }

  async function logoutUser() {
    loginId.value = '';
    personId.value = '';
    emails.value = [];
    phoneNumbers.value = [];
    username.value = '';    
    router.push( { name: 'login' });
  }  

  async function registerUser(username, password, taxId, dateOfBirth) {
    let resp = await codeEngineCoin2CoreClient.value.registerUser(username, password, taxId, dateOfBirth);    
    if(resp.success) {        
      return true;
    } else {
      // This can have core specific messages around bad password stuff we want to preserve
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode, resp.errorMessage);
      return false;
    }
  }

  async function sendSecondaryAuthRequest(contactId) {
    let resp = await codeEngineCoin2CoreClient.value.sendSecondaryAuthRequest(username.value, contactId, secondaryAuthAction.value, loginId.value, portalTypeId.value);    
    if(resp.success) {
      secondaryAuthRequestId.value = resp.requestId;
      return true;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function verifySecondaryAuthRequest(contactId, passcode) {
    let resp = await codeEngineCoin2CoreClient.value.verifySecondaryAuthRequest(secondaryAuthRequestId.value, username.value, contactId, secondaryAuthAction.value, loginId.value, passcode, portalTypeId.value);    
    if(resp.success) {
      return true;
    } else {        
      if(resp.logoutUser) {
        showSecondaryAuth.value = false;
        logoutUser();        
        error.value = codeEngineStore.getPortalContentByPageAndContentName('SecondaryAuthentication', 'lock_out_popup_error'); //'You have failed secondary authentication, your account has been locked. Please contact someone who cares.';
      } else {           
        error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
        return false;
      }
    }
  }
  
  async function getCryptoCurrencies() {
    let resp = await codeEngineCoin2CoreClient.value.getConfiguredCurrencies();
    if(resp.success) {      
      configuredCurrencies.value = resp.currencies;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
    }
  }
    
  async function getCryptoWallet() {
    let resp = await codeEngineCoin2CoreClient.value.getCryptoBalances(personId.value);
    if(resp.success) {      
      cryptoWallets.value = resp.cryptoWallets;          
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
    }
  }

  async function getEstimatedFees(walletId, coinAmount, destinationAddress) {
    let resp = await codeEngineCoin2CoreClient.value.getEstimatedFees(personId.value, walletId, coinAmount, destinationAddress);
    if(resp.success) {            
      return resp;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function createVaultWithdraw(walletId, coinAmount, destinationAddress, feeAmount) {
    let resp = await codeEngineCoin2CoreClient.value.createVaultWithdraw(personId.value, walletId, coinAmount, destinationAddress, feeAmount);
    if(resp.success) {            
      return resp;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function addContactInfo(newValue, newCategory) {
    let resp = await codeEngineCoin2CoreClient.value.addContactInfo(personId.value, newValue, newCategory);
    if(resp.success) {      
      if(newCategory.indexOf('email') > -1) {
        let newEmail = {};
        newEmail.coreId = resp.coreId;
        newEmail.emailAddress = newValue;
        newEmail.typeDescription = newCategory;
        let splitEmail = newValue.split('@');        
        newEmail.maskedEmailAddress = splitEmail[0][0] + '****' + splitEmail[0][splitEmail[0].length - 1] + '@' + splitEmail[1];        
        emails.value.push(newEmail);
      } else {
        let newPhoneNumber = {};
        newPhoneNumber.coreId = resp.coreId;
        newPhoneNumber.number = newValue;
        newPhoneNumber.typeDescription = newCategory;
        newPhoneNumber.maskedNumber = 'XXX-XXX-X' + newValue.substring(newValue.length - 3);
        phoneNumbers.value.push(newPhoneNumber);
      }
      return true;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function updateContactInfo(contactId, newValue, newCategory) {
    let resp = await codeEngineCoin2CoreClient.value.updateContactInfo(personId.value, contactId, newValue, newCategory);
    if(resp.success) {      
      let phoneIdx = phoneNumbers.value.findIndex(x => x.coreId == contactId);
      if(phoneIdx > -1) {        
        phoneNumbers.value[phoneIdx].maskedNumber = 'XXX-XXX-X' + newValue.substring(newValue.length - 3);;
      }

      let emailIdx = emails.value.findIndex(x => x.coreId == contactId);
      if(emailIdx > -1) {        
        let splitEmail = newValue.split('@');        
        emails.value[emailIdx].maskedEmailAddress = splitEmail[0][0] + '****' + splitEmail[0][splitEmail[0].length - 1] + '@' + splitEmail[1];        
      }

      return true;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function deleteContactInfo(contactId) {
    let resp = await codeEngineCoin2CoreClient.value.deleteContactInfo(personId.value, contactId);
    if(resp.success) {              
      let phoneIdx = phoneNumbers.value.findIndex(x => x.coreId == contactId);
      if(phoneIdx > -1) {
        phoneNumbers.value.splice(phoneIdx, 1);
      }

      let emailIdx = emails.value.findIndex(x => x.coreId == contactId);
      if(emailIdx > -1) {
        emails.value.splice(emailIdx, 1);
      }
      return true;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function sendForgotPasswordLink(un, phone, email, ssn) {
    let resp = await codeEngineCoin2CoreClient.value.sendForgotPasswordLink(un, phone, email, ssn, portalTypeId.value, location.origin);
    if(resp.success) {
      return true;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function validatePasswordResetLink(rid, rtk) {
    let resp = await codeEngineCoin2CoreClient.value.validatePasswordResetLink(rid, rtk);
    if(resp.success) {
      return resp.requestId;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function completeForgotPassword(requestId, pwd) {
    let resp = await codeEngineCoin2CoreClient.value.completeForgotPassword(requestId, pwd, portalTypeId.value);
    if(resp.success) {
      return true;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode, resp.errorMessage);
      return false;
    }
  }

  async function getWalletHistory(walletId) {
    let resp = await codeEngineCoin2CoreClient.value.getWalletHistory(personId.value, walletId) 
    if(resp.success) {
      return resp;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return null;
    }
  }  

  async function cancelVaultWithdraw(walletId, withdrawId) {
    let resp = await codeEngineCoin2CoreClient.value.cancelVaultWithdraw(personId.value, walletId, withdrawId);
    if(resp.success) {
      return true;
    } else {
      error.value = codeEngineStore.getErrorMappingByErrorCode(resp.errorCode);
      return false;
    }
  }

  async function startSecondaryAuthentication(action) {
    // If it's localhost, I've got an exception in here to bypass this, it doesn't HAVE to do this, but I figured it was easier for testing when repeating
    // If you need to test a new MFA flow, or something with the existing flow, just switch the logic or comment out the if
    if(location.hostname !== 'localhost') {
      secondaryAuthSuccess.value = false;
      secondaryAuthAction.value = action;
      showSecondaryAuth.value = true;    
      sendCodeFlow.value = true;
      verifyCodeFlow.value = false;   
    } else {
      // I have to make it unsuccessful, then successful to trigger the watches      
      secondaryAuthSuccess.value = false;
      await nextTick();
      secondaryAuthAction.value = action;             
      secondaryAuthSuccess.value = true;
    }
  }

  function finishSecondaryAuthentication() {    
    showSecondaryAuth.value = false;
    secondaryAuthSuccess.value = true;          
  }  

  return { 
    startSession,
    getConfiguration,
    getCryptoCurrencies,    
    validateIpAddress,
    loginUser,
    changeLoginPassword,
    startSecondaryAuthentication,
    finishSecondaryAuthentication,
    error, // Note, this should probably get moved out, and not just returned as a propery
    configuration,
    creditUnionName,
    creditUnionEmail,
    creditUnionPhone,    
    secondaryAuthAction,
    featureRequiresSecondaryAuth,
    isFeatureAvailable,
    updateContactInfo,
    addContactInfo,
    deleteContactInfo,
    registerUser,
    getWalletByType,
    getCryptoWallet,
    showSecondaryAuth,
    secondaryAuthSuccess,
    configuredCurrencies,
    portalBranding,
    sendSecondaryAuthRequest,
    verifySecondaryAuthRequest,
    personContacts,
    portalTimeout,
    loginId,
    logoutUser,
    getEstimatedFees,
    createVaultWithdraw,
    emails, 
    phoneNumbers,
    sendCodeFlow,
    verifyCodeFlow,
    passwordChangeRequired,    
    getPortalContentByPageName: codeEngineStore.getPortalContentByPageName, 
    getPortalContentByPageAndContentName: codeEngineStore.getPortalContentByPageAndContentName,    
    sendForgotPasswordLink,
    validatePasswordResetLink,
    completeForgotPassword,
    getWalletHistory,
    cancelVaultWithdraw,
   }
})
