<script setup>
import { ref, watch, toRefs } from 'vue';
import { onMounted } from '@vue/runtime-core';
import { useCoin2CoreStore } from '@/stores/coin2core';
import TimeoutModal from '@/components/modals/TimeoutModal.vue';
import SecondaryAuthenticationModal from '@/components/modals/SecondaryAuthenticationModal.vue';

const store = useCoin2CoreStore();
const loading = ref(false);
let config = ref(null);

function background() {
  if (store.portalBranding.backgroundImage != null) {
    return 'data:image/png;base64,' + store.portalBranding.backgroundImage;
  } else {
    return '';
  } 
}

onMounted(async () => {
  loading.value = true;  
  await store.startSession();
  let resp = await store.getConfiguration();        
  let root = document.querySelector(":root");
  root.style.setProperty("--PRIMARY_COLOR", store.portalBranding.primaryColor);
  root.style.setProperty("--SECONDARY_COLOR", store.portalBranding.secondaryColor);
  root.style.setProperty("--HIGHLIGHT_COLOR", store.portalBranding.highlightColor);
  root.style.setProperty("--DARK_COLOR", store.portalBranding.darkColor);
  root.style.setProperty("--LIGHT_COLOR", store.portalBranding.lightColor);
  root.style.setProperty("--ERROR_COLOR", store.portalBranding.errorColor);    
  root.style.setProperty("--BACKGROUND_COLOR", store.portalBranding.backgroundColor);
  root.style.setProperty("--FONT_COLOR", store.portalBranding.fontColor);  
  if (store.portalBranding.backgroundImage == null) {
    document.body.classList.add('without-background-image');
  } 
  await store.getCryptoCurrencies();        
  loading.value = false;      
});

</script>

<template>  
<body>
  <img :src="background()" id="backgroundimage" v-if="background() != ''"/>
  <div v-if="!loading">
    <RouterView />
    <Toast position="top-center" />
    <TimeoutModal />
    <SecondaryAuthenticationModal />
  </div>
  <div v-if="loading" class="flex flex-column align-items-center justify-content-center" style="margin-top:25vh">
    <h3 class="text-center">Please wait while our mad scientists collide a few atoms...</h3>
    <ProgressSpinner class="button-progress-spinner"/>
  </div>  
</body>
</template>